import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { deleteReplyPromise } from "routines/review";

import ReviewCardContext from "./ReviewCardContext";
import ReviewReplyInput from "./ReviewReplyInput";

import modalActions from "actions/modals";
import podcastActions from "actions/podcast";
import {
  selectSpecificPodcast,
  selectSpecificEpisode,
} from "selectors/podcast";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import { equals } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useConfirm from "hooks/useConfirm";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useIsPodcastOwner } from "hooks/useReviewReply";
import useRoutinePromises from "hooks/useRoutinePromises";
import useWindowSize from "hooks/useWindowSize";

const mobileReplyInputReviews = {
  reviewReplyInput: {
    margin: 0,
  },
  profileSelectTitle: {
    marginTop: 0,
  },
};

function ReviewCardProvider(props) {
  const {
    children,
    showReplyBadge,
    review,
    user,
    insideCarousel,
    entity: passedEntity,
    entity_type,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");
  const loggedInUser = useLoggedInUser();
  const [confirmFunc, confirmComponent] = useConfirm();

  const { showModal, closeModal, deleteReview } = useActionCreators({
    showModal: modalActions.showModal,
    closeModal: modalActions.closeModal,
    deleteReview: podcastActions.deleteReview,
  });

  const { deleteReply } = useRoutinePromises({
    deleteReply: deleteReplyPromise,
  });

  const [showReviewReplyInput, setShowReviewReplyInput] = useState(false);

  // const { canReply } = useContext(ReviewCardContext);

  // TODO: We don't need to know about podcast/episode in this component, but this seems to be the best place to fetch the podcast item for now.
  const podcast_id =
    review &&
    review.getIn(["entity", "podcast"]) &&
    (typeof review.getIn(["entity", "podcast"]) === "number" ||
      typeof review.getIn(["entity", "podcast"]) === "string")
      ? review.getIn(["entity", "podcast"])
      : null;
  const podcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcast_id),
    [podcast_id]
  );
  const episode = useReduxState(
    (state) =>
      selectSpecificEpisode(
        state,
        review && review.get("entity_type") === "episode"
          ? review.get("entity_id")
          : null
      ),
    [review]
  );
  const podcastEntity = useReduxState(
    (state) =>
      selectSpecificPodcast(
        state,
        review && review.get("entity_type") === "podcast"
          ? review.get("entity_id")
          : (episode && episode.get("podcast_id")) || null
      ),
    [review, episode]
  );

  const isPodcastOwner = useIsPodcastOwner(podcast, podcastEntity);

  const isReviewOwner = useCallback(
    (reply = null, passedOwner = null) => {
      if (loggedInUser) {
        const ent = reply || review;

        if (
          ent &&
          (equals(ent.get("user_id"), loggedInUser.get("id")) ||
            (!reply && user && equals(user.get("id"), loggedInUser.get("id"))))
        ) {
          return true;
        }
        // Check if loggedInUser is owner of the review 'user' (which is actually a podcast)
        const owner = passedOwner || user;

        if (owner.get("owner_ids")) {
          return owner
            .get("owner_ids")
            .find((ow) => ow === loggedInUser.get("id"));
        }
      }
      return false;
    },
    [loggedInUser, review, user]
  );

  const canReply = useMemo(
    () =>
      showReplyBadge &&
      loggedInUser &&
      isPodcastOwner &&
      review.get("user_id") !== loggedInUser.get("id") &&
      !review.get("has_reply"),
    [showReplyBadge, loggedInUser, review, isPodcastOwner]
  );

  const entityWithPodcast = useMemo(() => {
    let newEntity = review;
    if (podcast_id && podcast && newEntity) {
      newEntity = newEntity.setIn(["entity", "podcast"], podcast);
    }
    if (user && newEntity) {
      newEntity = newEntity.set("user", user);
    }
    return newEntity;
  }, [review, podcast, podcast_id, user]);

  const handleDelete = useCallback(
    (reply = null) => {
      confirmFunc(
        `Are you sure you want to delete your ${reply ? "reply" : "review"}?`,
        () => {
          if (reply) {
            deleteReply({
              review_id: review.get("id"),
              reply_id: reply.get("id"),
            });
          } else {
            deleteReview(review.get("entity_type"), review.get("entity_id"));
          }
        }
      );
    },
    [deleteReview, deleteReply, review, confirmFunc]
  );

  const handleReplyDelete = useCallback(
    (reply) => handleDelete(reply),
    [handleDelete]
  );

  const handleReviewDelete = useCallback(() => handleDelete(), [handleDelete]);

  const handleHideInput = useCallback(() => setShowReviewReplyInput(false), []);

  // const handleShowReplies = useCallback(() => setShowReviewReplies(prevShow => !prevShow), []);

  const renderMobileReplyContent = useCallback(
    () => (
      <ReviewReplyInput
        review={review}
        onClose={() => closeModal("generic")}
        user={user}
        styles={mobileReplyInputReviews}
      />
    ),
    [review, user, closeModal]
  );

  const handleToggleInput = useCallback(() => {
    const entity = passedEntity || entityWithPodcast;

    sendGAEvent({
      action: "replyInputOpened",
      review_id: review.get("id"),
      entity_type: review.get("entity_type"),
      entity_id: review.get("entity_id"),
      entity_name: entity && (entity.get("title") || entity.get("name")),
    });

    if (mobile) {
      showModal("generic", {
        title: `Reply to ${getUserDisplayName(user)}'s review`,
        renderContent: renderMobileReplyContent,
      });
    } else {
      setShowReviewReplyInput((show) => !show);
    }
  }, [
    mobile,
    review,
    user,
    showModal,
    renderMobileReplyContent,
    entityWithPodcast,
    passedEntity,
  ]);

  const context = useMemo(
    () => ({
      canReply,
      entity_type,
      entity: passedEntity || entityWithPodcast,
      onToggleInput: handleToggleInput,
      showReviewReplyInput,
      onHideInput: handleHideInput,
      onDeleteReply: handleReplyDelete,
      onDeleteReview: handleReviewDelete,
      review,
      user,
      podcast: podcastEntity,
      confirmFunc,
      confirmComponent,
      isReviewOwner,
      insideCarousel,
      isPodcastOwner,
    }),
    [
      canReply,
      isReviewOwner,
      showReviewReplyInput,
      entityWithPodcast,
      handleToggleInput,
      handleHideInput,
      handleReplyDelete,
      handleReviewDelete,
      review,
      user,
      podcastEntity,
      confirmFunc,
      confirmComponent,
      insideCarousel,
      passedEntity,
      entity_type,
      isPodcastOwner,
    ]
  );

  return (
    <ReviewCardContext.Provider value={context}>
      {children}
    </ReviewCardContext.Provider>
  );
}

ReviewCardProvider.propTypes = {
  review: PropTypes.instanceOf(Map),
  children: PropTypes.node,
  user: PropTypes.instanceOf(Map),
  showReplyBadge: PropTypes.bool,
  insideCarousel: PropTypes.bool,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
};

ReviewCardProvider.defaultProps = {
  review: Map(),
  children: null,
  user: null,
  showReplyBadge: true,
  insideCarousel: false,
  entity: null,
  entity_type: null,
};

export default ReviewCardProvider;
