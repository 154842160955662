import Loadable from "@loadable/component";
import React from "react";

const ConfirmModal = Loadable(() => import("./ConfirmModal"));

function ConfirmModalAsyncLoading() {
  return null;
}

const ConfirmModalContainer = (props) => {
  return <ConfirmModal {...props} fallback={<ConfirmModalAsyncLoading />} />;
};

export default ConfirmModalContainer;
