import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const EditableText = Loadable(() => import("./EditableText"));

function EditableTextAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const EditableTextContainer = (props) => {
  return <EditableText {...props} fallback={<EditableTextAsyncLoading />} />;
};

export default EditableTextContainer;
