import { entityNames } from "constants/entity";
import getUserDisplayName from "utils/entity/getUserDisplayName";

export default function getReviewName(entity) {
  if (!entity) {
    return "";
  }
  if (
    entity.has("user_entity") ||
    (entity.has("user") && typeof entity.get("user") === "object")
  ) {
    const usersName = getUserDisplayName(
      entity.get("user_entity") || entity.get("user"),
      true
    );

    if (entity.has("reviewed")) {
      return `${usersName}'s review of "${entity.get("title")}"`;
    }

    return `${usersName}'s ${entityNames[entity.get("entity_type")]} review`;
  }

  return `${entity.get("entity_type")} review`;
}
