import { useCallback, useState } from "react";

import ConfirmModal from "components/Common/Modals/ConfirmModalAsync";

function useConfirm() {
  const [toRender, setToRender] = useState(null);

  const onClose = useCallback(() => {
    setToRender(null);
  }, []);

  const confirmFunc = useCallback(
    (confirmText, onConfirm) => {
      setToRender(
        <ConfirmModal
          confirmText={confirmText}
          onClose={onClose}
          confirmFunction={onConfirm}
        />
      );
    },
    [onClose]
  );

  return [confirmFunc, toRender];
}

export default useConfirm;
