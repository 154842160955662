import { Map } from "immutable";

import getCategoryText from "./getCategoryText";

import getCreatorName from "utils/entity/getCreatorName";
import getReviewName from "utils/entity/getReviewName";
import getUserDisplayName from "utils/entity/getUserDisplayName";

export default function getEntityName(entity, type, options = {}) {
  if (!entity || !Map.isMap(entity) || entity.size === 0) {
    return null;
  }

  switch (type) {
    case "affiliate":
      return entity.get("label") || entity.get("title");
    case "tag":
      return getCategoryText(entity);
    case "creator":
      return getCreatorName(entity, options);
    case "review": {
      return getReviewName(entity, options);
    }
    case "user":
      return getUserDisplayName(entity, options.informal);
    default:
      return entity.get("title");
  }
}
